import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GAME_STATUS } from '#/config/constants';
import { getTranslation } from '#/services/i18n';
import gameTheme from './game.scss';

const GameCard = ({ data, theme }) => {
  console.log('status themes:', theme, gameTheme);

  const getGameStatus = status => {
    if (status === GAME_STATUS.LIVE || status === 'inprogress') {
      return getTranslation('liveGameStatus');
    }

    if (status === GAME_STATUS.FINAL || status === 'closed') {
      return getTranslation('finalGameStatus');
    }

    if (status === GAME_STATUS.SCHEDULED || status === 'scheduled') {
      return getTranslation('scheduleGameStatus');
    }

    return '';
  };

  // TODO: Check back how status was implemented

  const isScheduled =
    data?.status === GAME_STATUS.SCHEDULED || data?.status === 'scheduled';
  const isFinal =
    data?.status === GAME_STATUS.FINAL || data?.status === 'closed';

  console.log('statusdata:', data);

  return (
    <div className={gameTheme.gameContainer}>
      <div className={theme.overlay}>
        <div className={gameTheme.wrapper}>
          <img
            src={data?.homeTeamLogo}
            alt={data?.homeTeam}
            className={gameTheme.backgroundLeft}
          />
          <img
            src={data?.awayTeamLogo}
            alt={data?.homeTeam}
            className={gameTheme.backgroundRight}
          />
          <div className={gameTheme.titleSection}>
            <p className={gameTheme.titleHeader}>
              {data?.awayCity} Vs {data?.homeCity}
            </p>
            <p className={gameTheme.titleDate}>{data?.fullDate}</p>
          </div>
          <div className={gameTheme.teamSection}>
            <div className={gameTheme.teamContainer}>
              <img
                src={data?.homeTeamLogo}
                className={gameTheme.teamLogo}
                alt={data?.homeTeam}
              />
              <span>{data?.homeTeamScore}</span>
            </div>
            <div className={gameTheme.teamContainer}>
              <img
                src={data?.awayTeamLogo}
                className={gameTheme.teamLogo}
                alt={data?.awayTeam}
              />
              <span>{data?.awayTeamScore}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

GameCard.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object
};

export default GameCard;
