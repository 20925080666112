// This is a header for TV only.
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';

import useAppConfig from '#/hooks/useAppConfig';
import usePage from '#/hooks/usePage';
import useMenu from '#/hooks/useMenu';
import useI18n from '#/hooks/useI18n';
import Logo from '#/components/Logo/Logo';
import Menu from '#/components/Menu/Menu';
import { navIdMap } from '#/utils/navigationHelper';
import getResolution from '#/utils/getResolution';
import { R720p } from '#/theme/variables/breakpoints';

import styles from './tvHeader.scss';

const NAV_IDS = navIdMap.MENU.HEADER;

const nav = {
  container: {
    id: NAV_IDS.CONTAINER,
    forwardFocus: NAV_IDS.MENU,
    useLastFocus: true
  },
  menu: {
    id: NAV_IDS.MENU,
    parent: NAV_IDS.CONTAINER,
    forwardFocus: `${NAV_IDS.MENU_ITEM}0`,
    itemId: NAV_IDS.MENU_ITEM
  }
};

const TVHeader = ({ items }) => {
  const { getCurrentPageId } = usePage();
  const { config = {} } = useAppConfig();
  const { currentLocale = {} } = useI18n();
  const { isMenuVisible, openMenu, closeMenu, isMenuOpen } = useMenu();
  const focusCurrentPage = () => {
    const currentPageId = getCurrentPageId();
    currentPageId && focusManager.changeFocus(getCurrentPageId());
  };
  const { dir } = currentLocale;
  const { width } = getResolution();

  const containerClass = classNames(styles.container, {
    [styles.isVisible]: isMenuVisible,
    [styles.isOpen]: isMenuOpen
  });

  const overlayClass = classNames({
    [styles.overlay]: isMenuOpen
  });

  const imageClass = classNames(styles.img, styles.logo, styles[dir]);

  const wrappedClassName = classNames(
    styles.wrapper,
    isMenuOpen ? '' : styles.logoCollapsed
  );

  const customStyle = {
    ...(R720p >= width
      ? { paddingTop: '1rem', paddingBottom: '1rem' }
      : { padding: '1.5rem 50px 1.5rem 1.5rem' })
  };

  useEffect(() => {
    const handleFocusChanged = changedFocus => {
      if (changedFocus.currentFocus.indexOf(NAV_IDS.MENU_ITEM) > -1) {
        openMenu();
      } else {
        closeMenu();
      }
    };
    focusManager.listenToFocusChanged(handleFocusChanged);

    return () => {
      focusManager.unlistenToFocusChanged(handleFocusChanged);
    };
  }, [openMenu, closeMenu]);

  useEffect(() => {
    focusManager.changeFocus(nav.menu.id);
  }, []);

  return (
    <header dir={dir}>
      <div className={overlayClass} onMouseMove={() => closeMenu()} />
      <FocusDiv
        style={customStyle}
        className={containerClass}
        nav={{
          ...nav.container,
          internal: {
            nextright: focusCurrentPage
          }
        }}
        dir={dir}
      >
        <div className={wrappedClassName}>
          {/* src={config.appLogo} */}
          <Logo className={imageClass} />
          <Menu entryId={config.menuId} items={items} nav={nav.menu} />
        </div>
      </FocusDiv>
    </header>
  );
};

TVHeader.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default TVHeader;
